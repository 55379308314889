import React, { useState } from 'react'
import { FC } from '../../Services'
import { Button, Flex, Input, Card } from '../../Components'
import { useAuth } from '../../Provider/AuthProvider'
import { useCQuery } from '../../Services/QueryCache'
// import { useCQuery } from '../../Services/QueryCache'
// import { Text } from '../../Components/Common/Text'

const Dashboard = () => {
  const { data: codSocieties = [] } = useCQuery('codSocieties')
  const { user: userObj = {} } = useAuth()
  const { user = {} } = userObj || {}
  const { codSociety, atlanteUrl } = user || {}
  // const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()))
  // const [endDate, setEndDate] = useState(new Date())
  // const { data: stats } = useCQuery(['getStats', startDate.setHours(0, 0, 0, 0), endDate.setHours(23, 59, 59, 999)])
  const [minReceipt, setMinReceipt] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [maxReceipt, setMaxReceipt] = useState(new Date())
  const [noUpdate, setNoUpdate] = useState(false)
  const [codSocietySelected, setCodSocietySelected] = useState({ codSociety, atlanteUrl })

  const init = () => {
    FC.client.service('public').create({
      type: 'executeUnit',
      unit: 'processAllInvoices',
      codSociety: codSocietySelected?.codSociety,
      atlanteUrl: codSocietySelected?.atlanteUrl,
      minReceipt,
      maxReceipt,
      noUpdate
    })
    window.growl.show({ severity: 'success', summary: 'Processo avviato', detail: 'Processo inviato con successo' })
  }

  return (
    <>
      <Flex fw>
        <Card title='Invio manuale' style={{ width: '100%' }}>
          <Flex row js>
            <Input
              date value={minReceipt} id='minReceipt' label='Data ricezione inizio'
              onChange={({ minReceipt }) => setMinReceipt(minReceipt)} style={{ border: '1px solid #ccc', width: 300 }}
            />
            <Input
              date value={maxReceipt} id='maxReceipt' label='Data ricezione fine'
              onChange={({ maxReceipt }) => setMaxReceipt(maxReceipt)} style={{ marginLeft: 10, border: '1px solid #ccc', width: 300 }}
            />
            <Input
              dropdown
              id='noUpdate'
              label='Simula'
              value={noUpdate}
              onChange={({ noUpdate }) => setNoUpdate(noUpdate)}
              options={[{ label: 'No', value: false }, { label: 'Si', value: true }]}
              style={{ marginLeft: 10, border: '1px solid #ccc', width: 300 }}
            />
            <Input
              dropdown
              id='codSociety'
              label='Codice società'
              value={codSocietySelected}
              onChange={({ codSociety }) => setCodSocietySelected(codSociety)}
              options={codSocieties?.map(({ codSociety, atlanteUrl }) => ({ label: codSociety, value: { codSociety, atlanteUrl } })) || []}
              style={{ marginLeft: 10, border: '1px solid #ccc', width: 300 }}
            />

            <Button label='Processa tutte le fatture' onClick={init} style={{ marginLeft: 10 }} />
          </Flex>
        </Card>
        {/*  <Card title='Statistiche' style={{ width: '100%', marginTop: 20 }}>
          <Flex row js>
            <Input date id='startDate' label='Data inizio' value={startDate} onChange={({ startDate }) => setStartDate(startDate)} style={{ border: '1px solid #ccc', width: 300 }} />
            <Input date id='endDate' label='Data fine' value={endDate} onChange={({ endDate }) => setEndDate(endDate)} style={{ marginLeft: 10, border: '1px solid #ccc', width: 300 }} />
          </Flex>
          <Flex as style={{ marginTop: 10 }}>
            <Text value={'Resoconto fatture dal ' + startDate.toLocaleDateString() + ' al ' + endDate.toLocaleDateString()} bold size={16} />
            <Text value={'Fatture processate: ' + stats?.uniqueInvoices} bold size={16} />
            <Text value={'Fatture trovate: ' + stats?.nokInvoices} bold size={16} />
            <Text value={'Fatture non trovate: ' + (stats?.uniqueInvoices - stats?.nokInvoices)} bold size={16} />
            <Text value={'Percentuale di successo: ' + (stats?.nokInvoices / stats?.uniqueInvoices * 100).toFixed(2) + '%'} bold size={16} />
          </Flex>
        </Card> */}
      </Flex>
    </>
  )
}

export default Dashboard
